// This composable is used to format the remote path to query based on user input in a dynamic selector and
// to then format the results into options for the selector
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

const useDynamicSelector = ({ path, searchQuery }) => {
  const { state, dispatch } = useStore();

  // Text Search
  const searchTimeout = ref(null);
  const fullPath = computed(() => {
    const encodedQuery = encodeURIComponent(searchQuery.value.trim());
    const querySeparator = path.includes('?') ? '&' : '?';
    return `${path}${querySeparator}query=${encodedQuery}`;
  });
  const performSearch = () => {
    if (!searchQuery.value.trim()) return;
    dispatch('selector/performSearch', { path: fullPath.value });
  };
  watch(searchQuery, () => {
    if (searchTimeout.value) clearTimeout(searchTimeout.value);
    searchTimeout.value = setTimeout(performSearch, 350);
  });
  const options = computed(() => state.selector.searchCache[fullPath.value] || []);

  return { options };
};

export default useDynamicSelector;
