import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      openSelectorId: null, // Randomly generated uuid for instances of the selector components to ensure only one instance is open at once
      searching: false,
      searchCache: {},
    };
  },

  getters: {
  },

  mutations: {
    cacheSearchResult(state, { path, data }) {
      state.searchCache[path] = data;
    },
  },

  actions: {
    performSearch({ state, commit }, { path }) {
      commit('update', { module: 'selector', key: 'searching', value: true }, { root: true });
      return new Promise(() => {
        if (state.searchCache[path]) {
          commit('update', { module: 'selector', key: 'searching', value: false }, { root: true });
        } else {
          Network.get(path, {
            success: (data) => {
              commit('cacheSearchResult', { path, data });
            },
            failure: () => {
              dispatch('setErrorToast', 'Could not perform search', { root: true });
            },
            complete: () => {
              commit('update', { module: 'selector', key: 'searching', value: false }, { root: true });
            },
          });
        }
      });
    },
  },
};
