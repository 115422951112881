// This composable is used to record and access the selection status of options in a single-select selector
import { useStore } from 'vuex';

const useSingleSelector = ({ selection }) => {
  const { commit } = useStore();

  const selectOption = (option) => {
    selection.value = option;
    commit('update', { module: 'selector', key: 'openSelectorId', value: null });
  };

  const isSelected = (option, keyMap) => selection.value?.[keyMap.value] === option[keyMap.value];

  return { selectOption, isSelected };
};

export default useSingleSelector;
