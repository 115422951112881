import { mapState } from 'vuex';


export default {
  computed: {
    planDetails() {
      const { price, header, onboardingItems } = this;
      const freq = this.currentUser.plan_pricing_data.is_annual_frequency ? 'annual' : 'monthly';
      const pricingData = this.currentUser.plan_pricing_data.premium[freq];

      return {
        plan: 'premium',
        name: 'Premium',
        image: 'graphics/plans-premium',
        pairingItems: [],
        price: pricingData.long,
        header,
        onboardingItems,
        showPricingCalc: pricingData.is_default,
      };
    },

    header() {
      return { onboarding: 'EVERYTHING IN STANDARD, PLUS:' };
    },

    onboardingItems() {
      return [
        'Create and implement custom Donut Journeys with unlimited Slack and email messages per Journey',
        'Unlimited targeted introductions and fully customizable messages',
        'Use Intros and Watercooler in Enterprise Grid multi-workspace channels',
        'Unlimited custom celebration dates',
      ];
    },

    ...mapState([
      'currentUser',
      'currentPlan',
      'page',
    ]),
  },
};
