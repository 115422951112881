import { mapState } from 'vuex';

export default {
  computed: {
    planDetails() {
      const freq = this.currentUser.plan_pricing_data.is_annual_frequency ? 'annual' : 'monthly';
      const pricingData = this.currentUser.plan_pricing_data.paid_coffee[freq];
      return {
        plan: 'paid_coffee',
        name: 'Standard',
        price: pricingData.long,
        // TODO: is this used?
        finalPrice: this.quantitiesAndCosts.paid_coffee ? `$${this.quantitiesAndCosts.paid_coffee[freq]}/mo for your ${this.quantitiesAndCosts.paid_coffee.quantity} users` : '',
        image: 'graphics/plans-starter',
        pairingItems: this.pairingItems,
        header: { pairing: 'EVERYTHING IN FREE, PLUS:' },
        showPricingCalc: pricingData.is_default,
      };
    },

    pairingItems() {
      const details = [
        'Send up to 6 messages per Journey',
        'Unlimited Donut channels (includes Watercooler + Intros + Celebrations)',
        'Customized Intros group size and messaging',
        'Custom topics and themed topic packs for Watercoolers',
        'Advanced Intros logic like cross-team intros or lotteries',
        'Unlimited number of customizable messages for birthdays and work anniversaries',
        'Group birthday and anniversary celebrations daily, weekly, or monthly',
        'Celebrate up to 5 custom dates per channel',
      ];
      if (!this.currentUser.hris_free_promo) {
        details.push('Connect your HRIS to automatically enroll New Hires, set up cross-group Intros, and import Celebrations dates.');
      }

      return details;
    },

    ...mapState([
      'currentUser',
      'quantitiesAndCosts',
    ]),
  },
};
