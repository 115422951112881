import _ from 'lodash';
import Random from 'lib/random';
import Network from '../lib/api/network';


import ModalMutations from './modal/mutations';
import ModalActions from './modal/actions';

import UserActions from './user/actions';
import UserMutations from './user/mutations';

import TeamActions from './team/actions';

import EventsMutations from './events/mutations';
import EventsActions from './events/actions';

import CoffeeMutations from './coffee/mutations';
import CoffeeActions from './coffee/actions';
import CoffeeGetters from './coffee/getters';

import WatercoolerMutations from './watercooler/mutations';
import WatercoolerActions from './watercooler/actions';

import WatercoolerPromptMutations from './watercooler-prompt/mutations';
import WatercoolerPromptActions from './watercooler-prompt/actions';

import WatercoolerPromptAdjustmentActions from './watercooler-prompt-adjustment/actions';

import WatercoolerPackActions from './watercooler-pack/actions';

import WatercoolerPacksPromptActions from './watercooler-packs-prompt/actions';

import WatercoolerPacksWatercoolerActions from './watercooler-packs-watercooler/actions';

import CelebrationProgramMutations from './celebration-program/mutations';
import CelebrationProgramActions from './celebration-program/actions';
import CelebrationProgramGetters from './celebration-program/getters';

import CelebrationScheduleMutations from './celebration-schedule/mutations';
import CelebrationScheduleActions from './celebration-schedule/actions';

import CelebrationEventActions from './celebration-event/actions';

import ReportingActions from './reporting/actions';

import ChannelProgramActions from './channel-program/actions';
import ChannelProgramMutations from './channel-program/mutations';

import ServiceAccountActions from './service-account/actions';
import ServiceAccountMutations from './service-account/mutations';

import PaymentActions from './payment/actions';
import PaymentMutations from './payment/mutations';

import ChannelActions from './channel/actions';

import AssetImportActions from './asset-import/actions';
import AssetImportMutations from './asset-import/mutations';

import WizardMutations from './wizard/mutations';
import WizardActions from './wizard/actions';

import userInviting from './modules/user-inviting';
import templateLibrary from './modules/template-library';
import uploads from './modules/uploads';
import icebreakers from './modules/icebreakers';
import channelProgramsSendNow from './modules/channel-programs-send-now';
import hrisConnect from './modules/hris-connect';
import hrisManagePrograms from './modules/hris-manage-programs';
import hrisMapping from './modules/hris-mapping';
import onboardingBatches from './modules/onboarding-batches';
import onboardingEnrollmentResponses from './modules/onboarding-enrollment-responses';
import onboardingEnrollmentChannelJoin from './modules/onboarding-enrollment-channel-join';
import onboardingEnrollmentSlackJoin from './modules/onboarding-enrollment-slack-join';
import onboardingEnrollmentSurvey from './modules/onboarding-enrollment-survey';
import onboardingEnrollmentSurveyQuestionLibrary from './modules/onboarding-enrollment-survey-question-library';
import onboardingEventsCalendar from './modules/onboarding-events-calendar';
import onboardingManualEnrollment from './modules/onboarding-manual-enrollment';
import onboardingPresets from './modules/onboarding-presets';
import onboardingSessions from './modules/onboarding-sessions';
import onboardingTags from './modules/onboarding-tags';
import onboardingSiteDisplay from './modules/onboarding-site-display';
import onboardingSiteEditor from './modules/onboarding-site-editor';
import presetBundles from './modules/preset-bundles';
import reauthing from './modules/reauthing';
import reportingAttentionRequired from './modules/reporting-attention-required';
import reportingModules from './modules/reporting-modules';
import selector from './modules/selector';
import upcomingNewHires from './modules/upcoming-new-hires';
import oneClickOnboarding from './modules/one-click-onboarding';
import oneClickOnboardingHrisFlow from './modules/one-click-onboarding-hris-flow';
import csvSender from './modules/csv-sender';
import drawer from './modules/drawer';
import atsConnect from './modules/ats-connect';

import UserIntegrationActions from './user-integration/actions';
import UserIntegrationMutations from './user-integration/mutations';

export default {
  state: {},

  modules: {
    userInviting,
    templateLibrary,
    uploads,
    icebreakers,
    channelProgramsSendNow,
    hrisConnect,
    hrisManagePrograms,
    hrisMapping,
    onboardingBatches,
    onboardingEnrollmentResponses,
    onboardingEnrollmentChannelJoin,
    onboardingEnrollmentSlackJoin,
    onboardingEnrollmentSurvey,
    onboardingEnrollmentSurveyQuestionLibrary,
    onboardingEventsCalendar,
    onboardingManualEnrollment,
    onboardingPresets,
    onboardingSessions,
    onboardingTags,
    onboardingSiteDisplay,
    onboardingSiteEditor,
    presetBundles,
    reauthing,
    reportingAttentionRequired,
    reportingModules,
    selector,
    upcomingNewHires,
    oneClickOnboarding,
    oneClickOnboardingHrisFlow,
    csvSender,
    drawer,
    atsConnect,
  },

  mutations: {
    ...ModalMutations,
    ...UserMutations,
    ...EventsMutations,
    ...CoffeeMutations,
    ...WatercoolerMutations,
    ...WatercoolerPromptMutations,
    ...CelebrationProgramMutations,
    ...CelebrationScheduleMutations,
    ...ChannelProgramMutations,
    ...ServiceAccountMutations,
    ...PaymentMutations,
    ...AssetImportMutations,
    ...WizardMutations,
    ...UserIntegrationMutations,

    updateState(state, payload) {
      for (const member in payload) {
        state[member] = payload[member];
      }
    },

    update(state, payload) {
      if (payload.module) {
        state[payload.module][payload.key] = payload.value;
      } else {
        state[payload.key] = payload.value;
      }
    },

    updateCoffeeConfiguration(state, payload) {
      state.coffeeConfiguration[payload.key] = payload.value;
    },

    updateWatercooler(state, payload) {
      state.watercooler[payload.key] = payload.value;
    },

    updateCelebrationProgram(state, payload) {
      state.celebrationProgram[payload.key] = payload.value;
    },

    updateMeetingIntegrationSettings(state, payload) {
      state.meetingIntegrationSettings[payload.key] = payload.value;
    },

    updateEvent(state, payload) {
      state.event[payload.key] = payload.value;
    },

    updateEventAssets(state, files) {
      state.event.assets = files;
    },

    updateBrandAssets(state, files) {
      state.brandAssets = files;
    },

    startLoading(state, value) {
      state.loading.push(value);
    },
    endLoading(state, value) {
      state.loading = state.loading.filter(l => l !== value);
    },

    updateToaster(state, value) {
      let updatedToasts = { ...state.toaster.toasts, ...value };
      updatedToasts = Object.fromEntries(Object.entries(updatedToasts).filter(([k, v]) => v != null));
      state.toaster.toasts = updatedToasts;
    },

    startConfetti(state, value) {
      state.rainConfetti = true;
    },

    clickButton(state, payload) {
      state.buttonClicked = payload;
    },

    submitForm(state) {
      state.formSubmitted = true;
    },

    updateDrag(_state, { object, val }) {
      object.dragging = val;
    },

    updateSelexCache(state, { type, url, key, value }) {
      if (key) {
        state.selexCache[type][url][key] = value;
      } else {
        state.selexCache[type][url] = {};
      }
    },

    addAdmin(state, payload) {
      if (state.admins) {
        const index = _.findIndex(state.admins, admin => admin.id === payload.id);
        if (index < 0) {
          state.admins.push(payload);
        }
      }
    },

    removeAdmin(state, payload) {
      if (state.admins) {
        const index = _.findIndex(state.admins, admin => admin.id === payload.id);
        if (index >= 0) {
          state.admins.splice(index, 1);
        }
      }
    },

    updateError(state, payload) {
      if (!state.errors) {
        state.errors = {};
      }
      state.errors[payload.name] = payload.error;
    },

    addCategory(state, payload) {
      if (state.categories) {
        const index = _.findIndex(state.categories, category => category.id === payload.id);
        if (index < 0) {
          state.categories.push(payload);
        }
      }
    },
  },

  actions: {
    ...ModalActions,
    ...UserActions,
    ...TeamActions,
    ...EventsActions,
    ...CoffeeActions,
    ...WatercoolerActions,
    ...WatercoolerPromptActions,
    ...WatercoolerPromptAdjustmentActions,
    ...WatercoolerPackActions,
    ...WatercoolerPacksPromptActions,
    ...WatercoolerPacksWatercoolerActions,
    ...CelebrationProgramActions,
    ...CelebrationScheduleActions,
    ...CelebrationEventActions,
    ...ReportingActions,
    ...ChannelProgramActions,
    ...ServiceAccountActions,
    ...PaymentActions,
    ...ChannelActions,
    ...AssetImportActions,
    ...WizardActions,
    ...UserIntegrationActions,

    setToast({ commit }, toast) {
      const id = Random.uuid();
      commit('updateToaster', { [id]: toast });

      setTimeout(() => {
        commit('updateToaster', { [id]: null });
      }, toast.duration || 3500);
    },

    // duration is optional
    setSuccessToast({ dispatch }, payload) {
      let text;
      let duration = null;
      if (!payload || (typeof payload) === 'string') {
        text = payload;
      } else {
        text = payload.text;
        duration = payload.duration;
      }
      const toast = {
        type: 'success',
        text: text || 'Your preferences have been saved.',
        duration,
      };
      dispatch('setToast', toast);
    },

    setErrorToast({ dispatch }, text) {
      const toast = {
        type: 'error',
        text: text || 'Uh oh, something went wrong. Try again?',
      };
      dispatch('setToast', toast);
    },

    fetchFieldTestVariant(store, args) {
      return new Promise((resolve, reject) => {
        Network.post(
          '/field_test/variant_as_json',
          args,
          {
            success: (data) => {
              resolve(data);
            },
            error: (error) => {
              resolve(false);
            },
          },
        );
      });
    },
  },

  getters: {
    ...CelebrationProgramGetters,
    ...CoffeeGetters,
  },
};
