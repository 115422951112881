// This composable is used to record and access the selection status of options in a multi-select selector
import { nextTick } from 'vue';

const useMultiSelector = ({ selection, calculateSelectorMenuPosition }) => {
  async function delayedMenuPositionCalculation() {
    // If we've added enough new selections to warrant a new row, or removed enough to warrant less rows,
    // we need to re-run calculateSelectorMenuPosition. Do it after nextTick to give it a second to register.
    await nextTick();
    calculateSelectorMenuPosition();
  }

  const isSelected = (option, keyMap) => selection.value?.map(s => s[keyMap.value]).includes(option[keyMap.value]);

  const toggleOption = (option, keyMap) => {
    if (isSelected(option, keyMap)) {
      selection.value = selection.value.filter(s => s[keyMap.value] !== option[keyMap.value]);
    } else {
      selection.value = selection.value.concat(option);
    }
    delayedMenuPositionCalculation();
  };

  return { toggleOption, isSelected };
};

export default useMultiSelector;
